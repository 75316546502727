import { call, fork, put, take } from 'redux-saga/effects';
import { GET, POST, DELETE } from '@/api';
import ActionTypes from './DropActionTypes';
import Actions from './DropActions';
import { Log } from '@/utils/Log';
import { HOST_URL } from '@/config';
import { has } from '@/utils/ErrorConfirm';
import axios from 'axios';

function getErrRes(err) {
  const { data } = err.response;
  let code = 'error';
  let message = '请求出错';
  if (data && has.call(data, 'code')) {
    code = data.code;
  }
  if (data && has.call(data, 'message')) {
    message = data.message;
  }
  return { code, message };
}

// 发起任务
function* SendDropStartSaga(payload) {
  Log.debug('test the SendDropStartSaga saga\t');
  try {
    const url = `${HOST_URL}/api/v1/tasks`;

    const res = yield call(POST, url, payload);

    yield put(Actions.didSendDropStart(res));
  } catch (err) {
    Log.error('didSendDropStart err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didSendDropStart(getErrRes(err)));
  }
}

export function* WatchSendDropStartSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.SEND_DROP_START);
    yield fork(SendDropStartSaga, payload);
  }
}

// 获取任务的信息
function* GetDropAgentInfoIP(payload) {
  Log.debug('test the GetDropAgentInfoIP saga\t');
  try {
    const url = `${HOST_URL}/api/v1/tasks`;
    const res = yield call(GET, url, payload);
    yield put(Actions.didGetDropAgentInfoIP(res));
  } catch (err) {
    Log.error('didGetDropAgentInfo err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetDropAgentInfoIP(getErrRes(err)));
  }
}

export function* WatchGetDropAgentInfoIPSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_DROP_AGENT_INFO_IP);

    yield fork(GetDropAgentInfoIP, payload);
  }
}

// 添加请求函数
function* GetDropTaskResult(payload) {
  Log.debug('test the GetDropTaskResult saga\t');
  try {
    const url = `${HOST_URL}/api/v1/tasks/${payload.task_id}`;

    const res = yield call(GET, url);

    yield put(Actions.didGetDropTaskResult(res));
  } catch (err) {
    Log.error('didGetDropTaskResult err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetDropTaskResult(getErrRes(err)));
  }
}

export function* WatchGetDropTaskResult() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_DROP_TASK_RESULT);

    yield fork(GetDropTaskResult, payload);
  }
}

// 添加请求函数 请求获取cos
function* GetDropResultImage(payload) {
  Log.debug('test the GetDropResultImage saga\t');
  try {
    // cos链接
    const url = `${HOST_URL}/api/v1/tasks/image`;

    const res = yield call(GET, url, payload);

    yield put(Actions.didGetDropResultImage(res));
  } catch (err) {
    Log.error('didGetDropTaskResult err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetDropResultImage(getErrRes(err)));
  }
}

export function* WatchGetDropResultImage() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_DROP_RESULT_IMAGE);

    yield fork(GetDropResultImage, payload);
  }
}


// 添加请求函数 暂时使用开销数据结构
function* GetDropCost(payload) {
  Log.debug('test the GetDropCost saga\t');
  try {
    // cos链接
    const url = `${HOST_URL}/api/v1/agent/stat`;

    const res = yield call(GET, url, payload);

    yield put(Actions.didGetDropCost(res));
  } catch (err) {
    Log.error('did DropCost err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetDropCost(getErrRes(err)));
  }
}

export function* WatchGetDropCost() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_DROP_COST);

    yield fork(GetDropCost, payload);
  }
}

// 添加请求函数 请求获取cos
function* DeleteDropTask(payload) {
  Log.debug('test the DeleteDropTask saga\t');
  try {
    // cos链接
    const url = `${HOST_URL}/api/v1/tasks/${payload.tid}`;

    const res = yield call(DELETE, url);

    yield put(Actions.didDeleteDropTask(res));
  } catch (err) {
    Log.error('did DeleteDropTask err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didDeleteDropTask(getErrRes(err)));
  }
}

export function* WatchDeleteDropTask() {
  while (true) {
    const { payload } = yield take(ActionTypes.DELETE_DROP_TASK);

    yield fork(DeleteDropTask, payload);
  }
}

// 获取agent列表
function* GetDropAgentList(payload) {
  Log.debug('test the GetDropAgentList saga\t');
  try {
    // cos链接
    const url = `${HOST_URL}/api/v1/agents`;

    const res = yield call(GET, url, payload);

    yield put(Actions.didGetDropAgentList(res));
  } catch (err) {
    Log.error('did GetDropAgentList err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetDropAgentList(getErrRes(err)));
  }
}

export function* WatchGetDropAgentList() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_DROP_AGENT_LIST);

    yield fork(GetDropAgentList, payload);
  }
}


// 获取agent监控数据
function* GetAgentMonitorData(payload) {
  Log.debug('test the GetAgentMonitorData saga\t');
  try {
    // cos链接
    const url = `${HOST_URL}/api/v1/agent/stat`;

    const res = yield call(GET, url, payload);

    yield put(Actions.didGetAgentMonitorData(res));
  } catch (err) {
    Log.error('did GetAgentMonitorData err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetAgentMonitorData(getErrRes(err)));
  }
}

export function* WatchGetAgentMonitorData() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_AGENT_MONITOR_DATA);

    yield fork(GetAgentMonitorData, payload);
  }
}


// 获取用户uid
function* GetUserUid(payload) {
  Log.debug('test the GetUserUid saga\t');
  try {
    // cos链接
    const url = `${HOST_URL}/api/v1/users`;

    const res = yield call(GET, url, payload);

    yield put(Actions.didGetUserUid(res));
  } catch (err) {
    Log.error('did GetUserUid err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetUserUid(getErrRes(err)));
  }
}

export function* WatchGetUserUid() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_USER_UID);

    yield fork(GetUserUid, payload);
  }
}

// 发起追踪任务
function* StartDiskTracing(payload) {
  Log.debug('test the StartDiskTracing saga\t');
  try {
    const url = `${HOST_URL}/api/v1/tasks`;

    const res = yield call(POST, url, payload);

    yield put(Actions.didStartDiskTracing(res));
  } catch (err) {
    Log.error('did StartDiskTracing err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didStartDiskTracing(getErrRes(err)));
  }
}

export function* WatchStartDiskTracing() {
  while (true) {
    const { payload } = yield take(ActionTypes.START_DISK_TRACING);

    yield fork(StartDiskTracing, payload);
  }
}

// 创建组
function* CreateGroup(payload) {
  Log.debug('test the createGroup saga\t');
  try {
    const url = `${HOST_URL}/api/v1/group`;

    const res = yield call(POST, url, payload);

    yield put(Actions.didCreateGroup(res));
  } catch (err) {
    Log.error('did createGroup err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didCreateGroup(getErrRes(err)));
  }
}

export function* WatchCreateGroup() {
  while (true) {
    const { payload } = yield take(ActionTypes.CREATE_GROUP);

    yield fork(CreateGroup, payload);
  }
}

// 解散组
function* DisbandGroup(payload) {
  Log.debug('test the DisbandGroup saga\t');
  try {
    const url = `${HOST_URL}/api/v1/group?group_id=${payload.gid}`;

    const res = yield call(DELETE, url);

    yield put(Actions.didDisbandGroup(res));
  } catch (err) {
    Log.error('did DisbandGroup err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didDisbandGroup(getErrRes(err)));
  }
}

export function* WatchDisbandGroup() {
  while (true) {
    const { payload } = yield take(ActionTypes.DISBAND_GROUP);

    yield fork(DisbandGroup, payload);
  }
}

// 添加组成员
function* AddGroupMember(payload) {
  Log.debug('test the AddGroupMember saga\t');
  try {
    const response = yield axios({
      method: 'post',
      url: `${HOST_URL}/api/v1/group/member`,
      params: {
        group_id: payload.group_id,
        group_name: payload.group_name,
        user_name: payload.user_name,
      }
    });
    yield put(Actions.didAddGroupMember(response));
  } catch (err) {
    Log.error('did AddGroupMember err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didAddGroupMember(getErrRes(err)));
  }
}

export function* WatchAddGroupMember() {
  while (true) {
    const { payload } = yield take(ActionTypes.ADD_GROUP_MEMBER);

    yield fork(AddGroupMember, payload);
  }
}

// 移出成员
function* RemoveGroupMember(payload) {
  Log.debug('test the RemoveGroupMember saga\t');
  try {
    const url = `${HOST_URL}/api/v1/group/member`;

    const res = yield call(DELETE, url, payload);

    yield put(Actions.didRemoveGroupMember(res));
  } catch (err) {
    Log.error('did RemoveGroupMember err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didRemoveGroupMember(getErrRes(err)));
  }
}

export function* WatchRemoveGroupMember() {
  while (true) {
    const { payload } = yield take(ActionTypes.REMOVE_GROUP_MEMBER);

    yield fork(RemoveGroupMember, payload);
  }
}

// 获取group信息
function* GetGroup(payload) {
  Log.debug('test the GetGroup saga\t');
  try {
    const url = `${HOST_URL}/api/v1/group/${payload.group_id}`;

    const res = yield call(GET, url);

    yield put(Actions.didGetGroup(res));
  } catch (err) {
    Log.error('did GetGroup err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetGroup(getErrRes(err)));
  }
}

export function* WatchGetGroup() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_GROUP);

    yield fork(GetGroup, payload);
  }
}

// 获取group agent
function* GetGroupAgent(payload) {
  Log.debug('test the GetGroupAgent saga\t');
  try {
    const url = `${HOST_URL}/api/v1/group/agent`;

    const res = yield call(GET, url, payload);

    yield put(Actions.didGetGroupAgent(res));
  } catch (err) {
    Log.error('did GetGroupAgent err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetGroupAgent(getErrRes(err)));
  }
}

export function* WatchGetGroupAgent() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_GROUP_AGENT);

    yield fork(GetGroupAgent, payload);
  }
}

// 共享agent
function* AddGroupAgent(payload) {
  Log.debug('test the AddGroupAgent saga\t');
  try {
    const url = `${HOST_URL}/api/v1/group/agent`;

    const res = yield call(POST, url, payload);

    yield put(Actions.didAddGroupAgent(res));
  } catch (err) {
    Log.error('did AddGroupAgent err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didAddGroupAgent(getErrRes(err)));
  }
}

export function* WatchAddGroupAgent() {
  while (true) {
    const { payload } = yield take(ActionTypes.ADD_GROUP_AGENT);

    yield fork(AddGroupAgent, payload);
  }
}

// 停止共享agent
function* RemoveGroupAgent(payload) {
  Log.debug('test the RemoveGroupAgent saga\t');
  try {
    const url = `${HOST_URL}/api/v1/group/agent`;

    const res = yield call(DELETE, url, payload);

    yield put(Actions.didRemoveGroupAgent(res));
  } catch (err) {
    Log.error('did RemoveGroupAgent err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didRemoveGroupAgent(getErrRes(err)));
  }
}

export function* WatchRemoveGroupAgent() {
  while (true) {
    const { payload } = yield take(ActionTypes.REMOVE_GROUP_AGENT);

    yield fork(RemoveGroupAgent, payload);
  }
}

// 获取已加入组信息
function* GetGroups(payload) {
  Log.debug('test the GetGroups saga\t');
  try {
    const url = `${HOST_URL}/api/v1/groups`;

    const res = yield call(GET, url, payload);

    yield put(Actions.didGetGroups(res));
  } catch (err) {
    Log.error('did GetGroups err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetGroups(getErrRes(err)));
  }
}

export function* WatchGetGroups() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_GROUPS);

    yield fork(GetGroups, payload);
  }
}

function* GetUserAgents(payload) {
  Log.debug('test the GetUserAgents saga\t');
  try {
    const url = `${HOST_URL}/api/v1/user/agents`;

    const res = yield call(GET, url, payload);

    yield put(Actions.didGetUserAgents(res));
  } catch (err) {
    Log.error('did GetGroups err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetUserAgents(getErrRes(err)));
  }
}

export function* WatchGetUserGroups() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_USER_AGENTS);

    yield fork(GetUserAgents, payload);
  }
}

function* GetDropResultCallgraphImage(payload) {
  try {
    const url = `${HOST_URL}/api/v1/tasks/image`;
    const res = yield call(GET, url, payload);
    yield put(Actions.didGetDropResultCallgraphImage(res));
  } catch (err) {
    let code = 'error';
    let msg = err;
    if (err && has.call(err, 'code')) {
      code = err.code;
    }
    if (err && has.call(err, 'msg')) {
      msg = err.msg;
    }
    yield put(Actions.didGetDropResultCallgraphImage({ code, msg }));
  }
}

export function* WatchGetDropResultCallgraphImage() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_DROP_RESULT_CALLGRAPH_IMAGE);

    yield fork(GetDropResultCallgraphImage, payload);
  }
}

/**
 * 获取调用图json数据
*/
function* GetCallgraphData(payload) {
  try {
    const res = yield call(GET, payload.dataURL);
    yield put(Actions.didGetCallgraphData(res));
  } catch (err) {
    // NOTE: cos 错误后返回xml数据，这里直接将错误信息设置为xml数据
    const code = 'error';
    const msg = err.response.data;
    yield put(Actions.didGetCallgraphData({ code, msg }));
  }
}

export function* WatchGetCallgraphData() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_CALLGRAPH_DATA);

    yield fork(GetCallgraphData, payload);
  }
}


function* GetSharedTaskURL(payload) {
  try {
    const url = `${HOST_URL}/api/v1/task/sharedURL`;
    const res = yield call(GET, url, payload);
    yield put(Actions.didGetSharedTaskURL(res));
  } catch (err) {
    Log.error('did GetSharedTaskURL err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetSharedTaskURL(getErrRes(err)));
  }
}

export function* WatchGetSharedTaskURL() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_SHARED_TASK_URL);

    yield fork(GetSharedTaskURL, payload);
  }
}

function* GetTaskResult(payload) {
  try {
    const url = `${HOST_URL}/api/v1/task`;
    const res = yield call(GET, url, payload);
    yield put(Actions.didGetTaskResult(res));
  } catch (err) {
    Log.error('did GetTaskResult err:\t', err);
    if (err.code === 'canceled') {
      return;
    }
    yield put(Actions.didGetTaskResult(getErrRes(err)));
  }
}

export function* WatchGetTaskResult() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_TASK_RESULT);

    yield fork(GetTaskResult, payload);
  }
}

function* GetFlamegraphData(payload) {
  try {
    const res = yield call(GET, payload.dataURL, payload.params, payload.config ?? {});
    yield put(Actions.didGetFlamegraphData(res));
  } catch (err) {
    // NOTE: cos 错误后返回xml数据，这里直接将错误信息设置为xml数据
    const code = 'error';
    const msg = err.response.data;
    yield put(Actions.didGetFlamegraphData({ code, msg }));
  }
}

export function* WatchGetFlamegraphData() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_FLAMEGRAPH_DATA);

    yield fork(GetFlamegraphData, payload);
  }
}


function* RemoveAgent(payload) {
  try {
    const url = `${HOST_URL}/api/v1/agents/${payload}`;
    const res = yield call(DELETE, url);
    yield put(Actions.didRemoveAgent(res));
  } catch (err) {
    Log.error('did GetTaskResult err:\t', err);
    if (err.code === 'canceled') {
      return ;
    }
    yield put(Actions.didRemoveAgent(getErrRes(err)));
  }
}

export function* WatchRemoveAgent() {
  while (true) {
    const { payload } = yield take(ActionTypes.REMOVE_AGENT);

    yield fork(RemoveAgent, payload);
  }
}
