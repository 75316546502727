import React, { useState, useEffect } from 'react';
import { _Table, Card, Button, SearchBox, StatusTip, Tooltip, message } from '@tencent/tea-component';
import { Table, Input, MessagePlugin } from 'tdesign-react';
import PropTypes from 'prop-types';
import ButtonShareTask from '@/components/buttonShareTask';
import { CODE_TO_TASK_STATUS_INFO, TASK_TYPE_MAP, CODE_TO_MESSAGE } from '../../config';
import { taskTimeFormat } from '../../utils/DateTime';
import { withRouter } from 'react-router-dom';
import { getOAName } from '@/utils/Storage';
import { goodStatus } from '@/utils/ErrorConfirm';
import { HOST_URL } from '@/config';
import qs from 'qs';
import axios from 'axios';
import { Log } from '@/utils/Log';
import { Edit2Icon } from 'tdesign-icons-react';

// const { pageable, autotip, filterable } = Table.addons;

const ALL_VALUE = 114514; // magic number

// 表单组件
// 需要分页所以基于中间层来处理分页查询请求，其他的部分顺序传递
// 后续可以抽象成一个分页查询组件，适用于任何需要分页查询的场景
const TaskLists = (props) => {
  Log.debug('TaskLists', props);
  const { agent } = qs.parse(props.location?.search.slice(1));
  const filterAgent = agent ?? '';
    // hook
  const [username] = useState(getOAName());
  const [totalNumber, setTotalNumber] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [pageSize, setPageSize] = useState(props.initPageSize);
  const [pageIndex, setPageIndex] = useState(props.initPageIndex);
  const [keyword, setKeyword] = useState("");
  const [taskType, setTaskType] = useState(ALL_VALUE);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    queryTasksByPage();
  }, []);

  useEffect(() => {
    queryTasksByPage();
  }, [pageIndex, pageSize, keyword, taskType]);

  useEffect(() => {
    const pollingTasks = setInterval(() => {
      queryTasksByPage();
    }, 5000);
    return () => clearInterval(pollingTasks);
  }, [pageIndex, pageSize, keyword, taskType]);

  const queryTasksByPage = () => {
    setIsLoading(true);
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/tasks/pagination`,
      params: {
        username: [username],
        target_ip: filterAgent,
        page_num: pageIndex,
        page_size: pageSize,
        task_name: keyword,
        task_type: taskType,
      } })
      .then((response) => {
        if (goodStatus(response)) {
          setTasks(response.data.tasks.sort((a, b) => Date.parse(b.create_time.Time) - Date.parse(a.create_time.Time)));
          setTotalNumber(response.data.total);
          props?.getTaskNumber(response.data.total);
        } else {
          message.error({
            content: '用户信息获取失败,请重试',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  return  <>
    <IndexTaskList
      tasks={tasks}
      onSearchTask={ props.onSearchTask }
      viewTask={  props.viewTask }
      deleteTask = { props.deleteTask }
      viewTasks = { props.viewTasks }
      style={ props.style }
      bordered = { props.bordered }
      totalNumber = {totalNumber}
      pageIndex={pageIndex}
      pageSize={pageSize}
      loading = { isLoading }
      setKeyword={ setKeyword }
      setPageIndex={setPageIndex}
      setPageSize={setPageSize}
      setTaskType={setTaskType}
      updateTasks={queryTasksByPage}
    />
    </>;
};

TaskLists.propTypes = {
  tasks: PropTypes.array, // agent信息
  onSearchTask: PropTypes.func,  // 搜索任务
  viewTask: PropTypes.func,  // 查看任务
  viewTasks: PropTypes.func, // 历史任务详情
  deleteTask: PropTypes.func, // 删除任务
  style: PropTypes.object,
  bordered: PropTypes.any,
  history: PropTypes.object,
  location: PropTypes.object,
  initPageSize: PropTypes.number,
  initPageIndex: PropTypes.number,
  getTaskNumber: PropTypes.func,
};


TaskLists.defaultProps = {
  bordered: false,
  initPageSize: 4,
  initPageIndex: 1,
};

// 受控组件
const IndexTaskList = (props) => {
  Log.debug('IndexTaskList', props);
  const columns = [
    {
      title: <>任务名 (可编辑<Edit2Icon />)</>,
      colKey: 'name',
      align: 'center',
      ellipsis: true,
      width: 155,
      cell: ({row}) => (
        <span style={{ fontSize: 12 }}>{row.name}</span>
      ),
      edit: {
        component: Input,
        props: {
          clearable: true,
          autofocus: true,
        },
        validateTrigger: 'change',
        on: (editContext) => ({
          onBlur: () => {
            console.log('失去焦点', editContext);
          },
          onEnter: (ctx) => {
            ctx?.e?.preventDefault();
            console.log('onEnter', ctx);
          },
        }),
        abortEditOnEvent: ['onEnter'],
        onEdited: (context) => {
          console.log('Edit firstName:', context);
          Log.debug('onEdited', context.newRowData);
           axios({
            method: 'get',
            url: `${HOST_URL}/api/v1/task/rename`,
            params: {
              ids: context.newRowData.id,
              task_name: context.newRowData.name,
            } })
            .then((response) => {
              Log.debug("rresponse", response);
              if (response.code === 0) {
                // 请求成功
                MessagePlugin.success('任务更名成功');
                props.updateTasks();
              } else {
                // 请求失败
                MessagePlugin.success('任务更名失败');
              }
            })
            .catch((error) => {
              console.log(error);
            });
        },
        // 校验规则，此处同 Form 表单
        rules: [
          {
            required: true,
            message: '不能为空',
          },
        ],
      },
      // 默认是否为编辑状态
      defaultEditable: true,
    },

    {
      title: '目标机器IP',
      colKey: 'target_ip',
      align: 'center',
      cell: ({row}) => (
        row.target_ip === '-' ? <Tooltip title="该任务存在多个目标主机，请查看任务详情">{<span style={{ fontSize: 12 }}>{row.target_ip}</span>}</Tooltip>
        :  <span style={{ fontSize: 12 }}>{row.target_ip}</span>
      ),
    },
    {
      title: '任务类型',
      colKey: 'type',
      align: 'center',
      ellipsis: true,
      filter: {
        type: 'single',
        list: [
          {
            label: '通用分析',
            value: 0,
          },
          {
            label: 'Java采样分析',
            value: 1,
          },
          {
            label: '磁盘追踪',
            value: 2,
          },
          {
            label: '时序分析',
            value: 3,
          },
          {
            label: '虚拟内存泄漏检测',
            value: 4,
          },
          {
            label: 'Golang内存分析',
            value: 5,
          },
          {
            label: 'Java堆分析',
            value: 6,
          },
          {
            label: '物理内存泄漏检测',
            value: 7,
          },
          {
            label: 'Jeprof内存分析',
            value: 8,
          },
          {
            label: '动态检测',
            value: -1,
          },
        ],
      },
      cell: ({row}) => (<span style={{ fontSize: 12 }}>{TASK_TYPE_MAP[row.type]}</span>),
      width: 100,
    },
    {
      title: '新建时间',
      colKey: 'create_time',
      align: 'center',
      ellipsis: true,
      cell: ({row}) => (
        <span style={{ fontSize: 12 }}>{taskTimeFormat(row.create_time)}</span>
      ),
      width: 152,
    },
    {
      title: '执行时间',
      colKey: 'begin_time',
      align: 'center',
      ellipsis: true,
      cell: ({row}) => (
        <span style={{ fontSize: 12 }}>{taskTimeFormat(row.begin_time)}</span>
      ),
      width: 152,
    },

    {
      title: '结束时间',
      colKey: 'end_time',
      align: 'center',
      ellipsis: true,
      cell: ({row}) => (
        <span style={{ fontSize: 12 }}>{taskTimeFormat(row.end_time)}</span>
      ),
      width: 152,
    },
    {
      title: '状态',
      colKey: 'status',
      align: 'center',
      cell: ({row}) => (
        <span style={{ fontSize: 12 }}>{CODE_TO_TASK_STATUS_INFO(row.status * 10 + row.analysis_status)}</span>
        ),
      width: 170,
    },
    {
      title: '结果',
      colKey: 'result',
      align: 'center',
      cell: ({row}) => (
        <span style={{ fontSize: 12 }}>{getstatus(row)}</span>
        ),
      width: 170,
    },
    {
      title: '操作',
      colKey: 'shareTask',
      align: 'center',
      cell: ({row}) => (<ButtonShareTask task={row}></ButtonShareTask>),
      width: 60,
    },
    {
      title: '操作',
      colKey: 'action',
      align: 'center',
      cell: ({row}) => (<Button type="link" onClick={ () => props.deleteTask(row)}>删除</Button>),
      width: 60,
    },
  ];
    // 分页数据变化
  async function rehandleChange(pageInfo) {
      const { current, pageSize } = pageInfo;
      props.setPageIndex(current);
      props.setPageSize(pageSize);
  }

  const onFilterChange = (filters, _col) => {
    props.setTaskType(filters.type);
  };

  function getstatus(row) {
    if (row.status === 4) {
      if (row.analysis_status === 0) {
        return <StatusTip status="loading" loadingText="分析中">
        </StatusTip>;
      }
      return <Button type="link" onClick={
          () => {
            props.viewTask(row);
          }
        }>查看</Button>;
    }
    if (row.analysis_status === 3 | row.status === 3) {
    // todo: 添加更多错误信息
      if (row.status_info === 'Unrecognized task and profiler type') {
        return  <>agent不支持该类任务,请升级agent到最新版本</>;
      }
      return <>{ CODE_TO_MESSAGE(row.status_info) }</>;
    }
    if (row.status !== 2) {
      return <StatusTip status="loading" loadingText="执行中">
                  </StatusTip>;
    }
    if (row.analysis_status !== 2) {
      return <StatusTip status="loading" loadingText="分析中">
                  </StatusTip>;
    }
    return <Button type="link" onClick={
      () => {
        props.viewTask(row);
      }
    }>查看</Button>;
  }
  return (
    <div style = {props.style}>
        <Card.Body
          title="历史任务"
          subtitle={(props.viewTasks)
            ? <>
              <Button style={{ marginLeft: '20px' }} type = 'link' onClick={ () => props.viewTasks() }>历史任务详情
              </Button>
            </>
            : ''
          }
          operation = {
              <>
                <SearchBox
                  value={ props.keyword }
                  onSearch={keyword => props.setKeyword(keyword)}
                  onClear={() => props.setKeyword('')}
                  placeholder="输入任务名或IP查询"
                  />
              </>
          }
        >
        {
          <div>
            <Table
          columns = { columns }
          recordKey = 'id'
          data = { props.tasks }
          onFilterChange={onFilterChange}
          // bordered = {props.bordered}
          pagination={{
            current: props.pageIndex,
            pageSize: props.pageSize,
            total: props.totalNumber,
            showJumper: true,
            onChange(pageInfo) {
              rehandleChange(pageInfo);
            },
          }}
        ></Table></div>
    }
        </Card.Body>
    </div>
  );
};

IndexTaskList.propTypes = {
  tasks: PropTypes.array, // agent信息
  onSearchTask: PropTypes.func,  // 搜索任务
  viewTask: PropTypes.func,  // 查看任务
  viewTasks: PropTypes.func, // 历史任务详情
  deleteTask: PropTypes.func, // 删除任务
  style: PropTypes.object,
  bordered: PropTypes.any,
  totalNumber: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  keyword: PropTypes.string,
  taskType: PropTypes.any,
  loading: PropTypes.bool,
  setPageIndex: PropTypes.func,
  setPageSize: PropTypes.func,
  setKeyword: PropTypes.func,
  setTaskType: PropTypes.func,
  updateTasks: PropTypes.func,
};


IndexTaskList.defaultProps = {
  pageSize: 4,
  pageIndex: 1,
  bordered: false,
  totalNumber: 0,
};

export default withRouter(TaskLists);
