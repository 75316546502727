// task result page
// 磁盘追踪任务展示页面

import React, { useEffect, useState } from 'react';
import DiskTracingResult from '@/pages/tracing/diskTracingResult';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import {
  message,
  notification,
  StatusTip,
  Modal,
} from '@tencent/tea-component';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import DropActionCreator from '@/api/DropActions';
import { connect } from 'react-redux';
import { goodStatus, doesntHaveStatus, badStatus } from '@/utils/ErrorConfirm';
import SamplingResult from './samplingResult';
import { timeToTimestmp } from '@/utils/DateTime';
import { Log } from '@/utils/Log';
import SamplingResultV2 from './samplingResultV2';
import TimingSamplingResult from '@/pages/timingSampling/timingSamplingResult';
import MemleakResult from './memleakResult';
import PprofheapResult from './golangHeapResult';
import DynamicDetectionResult from './dynamicDetection';
import axios from 'axios';
import JavaHeapResult from './javaHeapResult';
import { TASK_NAME_MAP } from '@/config';
import { HOST_URL } from '../../config';
import {
  Button,
  Dialog,
  NotificationPlugin,
  Form,
  Input,
  Rate,
  Space,
  MessagePlugin,
} from 'tdesign-react';

const FormItem = Form.FormItem;

const TaskResult = (props) => {
  const args = qs.parse(props.location.search.slice(1));
  const [taskID] = useState(args.taskID);
  const [taskExpiredTime] = useState(Number(args.taskExpiredTime));
  const [sharedTaskToken] = useState(args.sharedTaskToken);
  const [isSharedURL] = useState(args.isSharedURL === 'true');
  const [showGoBackButton] = useState(false);
  // hook data
  const [taskInfo, setTaskInfo] = useState({});
  const [taskResult, setTaskResult] = useState({});
  const [memtaskResult, setMemTaskResult] = useState({});
  const [analysisSuggestions, setAnalysisSuggestions] = useState({});
  const [diskData, setDiskData] = useState([]);
  const [jupyterLink, setJupyterLink] = useState('');
  const [startTimestamp, setStartTimestamp] = useState();
  const [endTimestamp, setEndTimestamp] = useState();
  const [containersInfo, setContainersInfo] = useState([]);
  const [analysisVersion, setAnalysisVersion] = useState('');
  const [timingAnalysisResult, setTimingAnalysisResult] = useState({});
  const [cosfiles, setCosfiles] = useState({
    flamegraph_file: "",
    callgraph_file: "",
    extended_flamegraph_file: "",
  });
  // status
  const [showLoading, setShowLoading] = useState(true);
  const [taskType, setTaskType] = useState('');
  const [showTaskScoreDialog, setShowTaskScoreDialog] = useState(false);
  const [form] = Form.useForm();
  const getDiskTraceTaskResult = (response) => {
    axios.get(response.data.cosInfo.preSignedURL).then((res) => {
      setDiskData(res.data);
      setShowLoading(false);
      notification.success({
        title: '通知',
        description: '获取数据成功',
      });
    })
      .catch((err) => {
        notification.error({
          title: '通知',
          description: `获取数据失败: ${err}`,
        });
        setShowLoading(false);
      });
  };
  // effect
  useEffect(() => {
    let param;
    if (isSharedURL) {
      // shared task
      param = {
        tid: taskID,
        expired_time: taskExpiredTime,
        token: sharedTaskToken,
        is_shared_task: '1',
      };
    } else {
      // user task
      param = {
        tid: taskID,
        is_shared_task: '0',
      };
    }
    props.DropAction.getTaskResult(param);
  }, []);

  const openNotification = (placement) => {
    NotificationPlugin.info({
      title: '提交反馈帮助我们做的更好',
      content: <Button onClick={()=>setShowTaskScoreDialog(true)}>反馈</Button>,
      placement,
      duration: 6000,
      closeBtn: true,
    });
  };

  const submitRate = (e) => {
    console.log(e);
    if (e.validateResult === false) {
      MessagePlugin.error('提交失败');
    }
    const data = e.fields;
    axios({
      method: 'post',
      url: `${HOST_URL}/api/v1/task/score`,
      data: {
        tid: taskID,
        score: data.rate,
        description: data.description,
      }
    })
      .then((response) => {
        if (goodStatus(response)) {
          MessagePlugin.success('提交成功');
        } else {
          MessagePlugin.error(`提交失败: ${response.msg}`);
        }
      })
      .catch((error) => {
        MessagePlugin.error(`提交失败: ${error}`);
      });
    setShowTaskScoreDialog(false);
  };

  useEffect(() => {
    const { GetTaskResult } = props.DropReducer;
    if (Object.keys(GetTaskResult).length) {
      // 错误回调
      if (doesntHaveStatus(GetTaskResult)) {
        message.error({
          content: `获取结果失败？？ ${GetTaskResult.message}`,
        });
        setShowLoading(false);
      } else if (badStatus(GetTaskResult)) {
        message.error({
          content: `获取结果失败！！ ${GetTaskResult.message}`,
        });
        setShowLoading(false);
      }
      if (goodStatus(GetTaskResult)) {
        // 处理返回结果
        openNotification('top-right');
        setTaskInfo(GetTaskResult.data.taskInfo);
        if (isSharedURL) {
          document.title = `${GetTaskResult.data.taskInfo.user_name}分享的任务 - Drop`;
        }
        Log.debug('taskinfo type:', GetTaskResult.data.taskInfo.type);
        switch (GetTaskResult.data.taskInfo.type) {
          case TASK_NAME_MAP.DynamicDetection:
            setTaskType('dynamicDetection');
            setShowLoading(false);
            break;
          case TASK_NAME_MAP.TracingDiskTraceTask:
            setTaskType('disk');
            setStartTimestamp(timeToTimestmp(GetTaskResult.data.taskInfo.begin_time.Time));
            setEndTimestamp(timeToTimestmp(GetTaskResult.data.taskInfo.end_time.Time));
            getDiskTraceTaskResult(GetTaskResult);
            break;
          case TASK_NAME_MAP.ProfilingTimingAnalysisTask:
            // timing analysis
            setTaskType('timingSamplingAnalysis');
            // Log.debug('taskresult:', GetTaskResult.data.taskResult);
            setShowLoading(false);
            setJupyterLink(GetTaskResult.data.jupyterLink);
            setTaskResult(GetTaskResult.data.taskResult);
            setTimingAnalysisResult(GetTaskResult.data.timingAnalysisResult);
            break;
          case TASK_NAME_MAP.PprofMemLeakTask:
            setTaskType('pprofheap');
            setShowLoading(false);
            // setJupyterLink(GetTaskResult.data?.jupyterLink);
            setTaskResult(GetTaskResult.data.taskResult);
            setAnalysisSuggestions(GetTaskResult.data.suggestions);
            break;
          case TASK_NAME_MAP.PageFaultTask:
          case TASK_NAME_MAP.JeprofTask:
          case TASK_NAME_MAP.TracingMemLeakTask: {
            setTaskType('memleak');
            setShowLoading(false);
            setCosfiles(GetTaskResult.data?.cosfiles);
            let data = GetTaskResult.data.taskResult;
            if (GetTaskResult.data.taskResult == '') {
              data = '[]';
            }
            setMemTaskResult(JSON.parse(data));
            setTaskResult(GetTaskResult.data.result);
            setAnalysisSuggestions(GetTaskResult.data.suggestions);
            break;
          }
          case TASK_NAME_MAP.TracingJavaHeapTask: {
            setTaskType('javaheap');
            setShowLoading(false);
            const data = GetTaskResult.data.taskResult;
            setTaskResult(data);
            break;
          }
          default:
            // general_analysis_result v1.1.0
            if (GetTaskResult.data.analysisVersion) {
              setTaskType('newSampling');
              setShowLoading(false);
              setJupyterLink(GetTaskResult.data?.jupyterLink);
              setTaskResult(GetTaskResult.data.taskResult);
              const { containersInfo } = GetTaskResult.data;
              containersInfo[''] = '全局';
              setContainersInfo(containersInfo);
              setAnalysisVersion(GetTaskResult.data.analysisVersion);

              // 由于新版本的建议为data中独立的字段，如果没有该字段则为旧任务，需要将result中的建议转换为新格式
              if (GetTaskResult.data.suggestions === undefined) {
                Log.debug('convert suggestions to new format');
                GetTaskResult.data.suggestions = {};
                for (const namespace in GetTaskResult.data.taskResult) {
                  GetTaskResult.data.suggestions[namespace] = [];
                  for (const index in GetTaskResult.data.taskResult[namespace].suggestions) {
                    GetTaskResult.data.suggestions[namespace].push({
                      suggestion: GetTaskResult.data.taskResult[namespace].suggestions[index],
                      func: '',
                    });
                  }
                }
              }
              Log.debug('suggestions:', GetTaskResult.data.suggestions);
              setAnalysisSuggestions(GetTaskResult.data.suggestions);

              Log.debug('taskresult:', GetTaskResult.data.taskResult);
              Log.debug('containerinfo:', GetTaskResult.data.containersInfo);
            } else {
            // general && java
              setTaskType('sampling');
              Log.debug('taskresult:', GetTaskResult.data.taskResult);
              setShowLoading(false);
              setJupyterLink(GetTaskResult.data.jupyterLink);
              setTaskResult(GetTaskResult.data.taskResult);
              break;
            }
        }
      }
    }
  }, [props.DropReducer]);

  const diskTracing = (!showLoading && diskData.length !== 0) ? <DiskTracingResult

    diskData={diskData}
    startTimeStamp = {startTimestamp}
    endTimeStamp = {endTimestamp}
    key={taskInfo?.id}
    onClick = { () => {
      props.history.goBack();
    }}
    isSharedURL = {isSharedURL}
    showGobackButton = {showGoBackButton}
    ></DiskTracingResult> : '' ;

  const sampling = <SamplingResult
    key={taskInfo?.id}
    thread_data={taskResult?.thread_data}
    heap_stack_info={taskResult?.heap_stack_info}
    java_result={taskResult?.java_result}
    flamegraph_url={taskResult?.flamegraph_url}
    callgraph_url={taskResult?.callgraph_url}
    task_data={taskInfo}
    user_name={taskInfo?.user_name}
    cost_data={taskResult?.cost_data}
    container_list={taskResult?.container_list}
    jupyterURL={jupyterLink}
    goBack = {() => props.history.goBack()}
    showGobackButton = {showGoBackButton}
  />;

  const newSampling = <SamplingResultV2
    key={taskInfo?.id}
    task_data={taskInfo}
    containers_info={containersInfo}
    analysis_version = {analysisVersion}
    task_result={taskResult}
    analysis_suggestions={analysisSuggestions}
    goback = {() => props.history.goBack()}
    is_shared_url = {isSharedURL}
    jupyter_url={jupyterLink}
    expired_time = {taskExpiredTime}
    shared_token = {sharedTaskToken}
    showGobackButton = {showGoBackButton}
  />;

  const pprofheap = <PprofheapResult
    key={taskInfo?.id}
    task_data={taskInfo}
    containers_info={containersInfo}
    analysis_version = {analysisVersion}
    task_result={taskResult}
    analysis_suggestions={analysisSuggestions}
    goback = {() => props.history.goBack()}
    is_shared_url = {isSharedURL}
    jupyter_url={jupyterLink}
    expired_time = {taskExpiredTime}
    shared_token = {sharedTaskToken}
    showGobackButton = {showGoBackButton}
  />;

  const javaHeapResult = <JavaHeapResult
    key={taskInfo?.id}
    task_info={taskInfo}
    goback={() => props.history.goBack()}
    jupyter_url={jupyterLink}
    hprof_result={taskResult?.hprof_result}
    showGobackButton = {showGoBackButton}
    analysis_version={analysisVersion}
  />;

  const timingSamplingAnalysis = <TimingSamplingResult
    key={taskInfo?.id}
    timing_analysis_result = {timingAnalysisResult}
    detection_results = {timingAnalysisResult?.detection_results}
    task_data={taskInfo}
    user_name={taskInfo?.user_name}
    cost_data={taskResult?.cost_data}
    container_list={taskResult?.container_list}
    jupyterURL={jupyterLink}
    goBack = {() => props.history.goBack()}
    is_shared_url = {isSharedURL}
    expired_time = {taskExpiredTime}
    shared_token = {sharedTaskToken}
    showGobackButton = {showGoBackButton}
  />;

  const memleakResult = <MemleakResult
    key={taskInfo?.id}
    task_info={taskInfo}
    goback={() => props.history.goBack()}
    jupyter_url={jupyterLink}
    memleak_results={memtaskResult}
    task_result={taskResult}
    showGobackButton = {showGoBackButton}
    analysis_suggestions={analysisSuggestions}
    containers_info={containersInfo}
    analysis_version={analysisVersion}
    flamegraph_file={cosfiles?.flamegraph_file}
    extended_flamegraph_file={cosfiles?.extended_flamegraph_file}
    callgraph_file={cosfiles?.callgraph_file}>
  </MemleakResult>;

  const dynamicDetectionResult = <DynamicDetectionResult
    key={taskInfo?.id}
    task_info={taskInfo}
    goback={() => props.history.goBack()}
    showGobackButton = {showGoBackButton}
    jupyter_url={jupyterLink}
    analysis_version={analysisVersion}
    all_analysis_suggestions={taskInfo.analysis_suggestions}
    history={props.history}
  />;

  const showContent = {
    disk: diskTracing,
    timingSamplingAnalysis,
    sampling,
    newSampling,
    pprofheap,
    memleak: memleakResult,
    dynamicDetection: dynamicDetectionResult,
    javaheap: javaHeapResult,
  };
  return <>
    {showContent[taskType]}
    <Modal visible={showLoading} caption="请稍候" onClose={() => setShowLoading(false)}>
      <Modal.Body><StatusTip status='loading' loadingText='获取数据中,请稍候'></StatusTip></Modal.Body>
    </Modal>
    <Dialog
      header="任务反馈"
      visible={showTaskScoreDialog}
      onClose={() => setShowTaskScoreDialog(false)}
      cancelBtn={false}
      confirmBtn={false}
    >
    <Form
      labelAlign="right"
      layout="vertical"
      preventSubmitDefault
      resetType="empty"
      showErrorMessage
      form={form} onSubmit={(e) => submitRate(e)}
    >
      <FormItem
        initialData={0}
        label="评分"
        name="rate"
      >
        <Rate
          showText
          texts={['完全没帮助', '无太大帮助', '一般', '有一定帮助', '很有帮助']}
        />
      </FormItem>
      <FormItem
        initialData=""
        label="描述"
        name="description"
      >
        <Input placeholder="请输入内容" />
      </FormItem>
      <FormItem
        style={{
          marginLeft: 300,
        }}
      >
        <Space>
          <Button type="submit" theme="primary">
            提交
          </Button>
        </Space>
      </FormItem>
    </Form>
    </Dialog>
  </>;
};

TaskResult.propTypes = {
  DropReducer: PropTypes.any,
  DropAction: PropTypes.any,
  location: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskResult));
