// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M7 1h10v2h4v20H3V3h4V1zm0 4H5v16h14V5h-2v2H7V5zm8-2H9v2h6V3zm-6 8h6v2H9v-2zm0 4h6v2H9v-2z"}}]};

const TaskIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'task',
    ref,
    icon: element,
  },
));

TaskIcon.displayName = 'TaskIcon';

export default TaskIcon;
