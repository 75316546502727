// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M16.43 1.96l5.6 5.61L7.62 22H2V16.4L16.43 1.96zm0 2.83l-2.78 2.78 2.78 2.79 2.78-2.79-2.78-2.78zM15 11.77l-2.78-2.78L4 17.22V20h2.78l8.23-8.23zM22.22 22h-9.54v-2h9.54v2z"}}]};

const Edit2Icon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'edit-2',
    ref,
    icon: element,
  },
));

Edit2Icon.displayName = 'Edit2Icon';

export default Edit2Icon;
