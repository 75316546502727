// 任务信息展示面板
import React from 'react';
import {
  Card,
  Form,
  Icon,
  Row,
  Col,
} from '@tencent/tea-component';
import PropTypes from 'prop-types';
import { VisableItem } from '../VisableItem';
import { Log } from '@/utils/Log';
import { taskTimeFormat } from '@/utils/DateTime';
import ButtonShareTask from '@/components/buttonShareTask';
import { getOAName } from '@/utils/Storage';
import { TASK_TYPE_MAP } from '@/config';

const itemSelector = [{
  perf_wide: true,
  container_type: true,
  profiling_freqence: true,
},
{
  perf_wide: true,
  profiler_type: true,
  profiling_freqence: true,
},
{
},
{
  profiling_freqence: true,
  perf_wide: true,
},
{
  tracing_wide: true,
},
{
},
{
},
{
},
{
}];


const wideOptionsToName = {
  comm: '进程名',
  pid: '进程pid',
  system: '全局',
};
// TaskInfoCard 负责展示任务信息
export const TaskInfoCard = (props) => {
  Log.debug('taskinfo', props);
  const requestParams = props.task_info.request_params;
  const wideOptions = requestParams.perf_record_options || requestParams.wide_options;
  const pid = requestParams.perf_record_pid || requestParams.pid;
  const freq = requestParams.perf_record_freq || requestParams.freq;
  const duration = requestParams.perf_record_duration || requestParams.duration;
  Log.debug(wideOptions);

  return <Card>
    <Card.Body title="任务信息">
      <Row showSplitLine>
        <Col span={8}>
          <Form readonly>
            <Form.Item label="任务名">
              <Form.Text>{props.task_info.name}</Form.Text>
            </Form.Item>
            <Form.Item label="任务ID">
              <Form.Text>{props.task_info.tid}</Form.Text>
            </Form.Item>
            <Form.Item label="目标机器">
           {props?.task_info?.hostname != "" ? <Form.Text>{props.task_info.target_ip} [{props.task_info.hostname}]</Form.Text>
           : <Form.Text>{props.task_info.target_ip}</Form.Text>
           }
            </Form.Item>
            <Form.Item label="任务状态">
              <Form.Text>执行成功，分析成功 <Icon type="success" /></Form.Text>
            </Form.Item>
            <Form.Item label="分析模块版本">
              <Form.Text>
                {props.analysis_version || '1.0.0'}
              </Form.Text>
            </Form.Item>
          </Form>
        </Col>
        <Col span={8}>
          <Form readonly>
            <Form.Item label="任务类型">
              <Form.Text>{TASK_TYPE_MAP[props.task_info.type]}</Form.Text>
            </Form.Item>
            <VisableItem visable={!!itemSelector[props.task_info.type].profiler_type}>
              <Form.Item label="采样器">
                <Form.Text>
                  {
                    props.task_info.request_params.perf_record_profiler_type === 0
                      ? 'perf' : 'async'}
                </Form.Text>
              </Form.Item>
            </VisableItem>
            <VisableItem
              visable={!!itemSelector[props.task_info.type].perf_wide
                || !!itemSelector[props.task_info.type].tracing_wide}>
              <Form.Item label="查询范围">
                <Form.Text>
                  {
                    wideOptionsToName[wideOptions]
                  }
                </Form.Text>
              </Form.Item>
            </VisableItem>
            <VisableItem
              visable={wideOptions !== 'system'}>
              <Form.Item label="查询目标">
                <Form.Text>
                  {
                    wideOptions === 'pid'
                      ? pid
                      : props.task_info.request_params.comm
                  }
                </Form.Text>
              </Form.Item>
            </VisableItem>
            <VisableItem visable={!!itemSelector[props.task_info.type].profiling_freqence}>
              <Form.Item label="采样频率(Hz)">
                <Form.Text>
                  {freq}
                </Form.Text>
              </Form.Item>
            </VisableItem>
            <Form.Item label="时长(秒)">
              <Form.Text>
                {duration}
              </Form.Text>
            </Form.Item>
            <VisableItem visable={props.task_info.type === 4}>
              <Form.Item label="内存分配器">
                <Form.Text>
                  {props.task_info.request_params.trace_object}
                </Form.Text>
              </Form.Item>
            </VisableItem>
          </Form>
        </Col>
        <Col span={8}>
          <Form readonly>
            <Form.Item label="创建时间">
              <Form.Text>{taskTimeFormat(props.task_info.create_time)}</Form.Text>
            </Form.Item>
            <Form.Item label="开始时间">
              <Form.Text>{taskTimeFormat(props.task_info.begin_time)}</Form.Text>
            </Form.Item>
            <Form.Item label="结束时间">
              <Form.Text>{taskTimeFormat(props.task_info.end_time)}</Form.Text>
            </Form.Item>
            <VisableItem visable={props.task_info.user_name === getOAName()}>
              <Form.Item label="操作">
                <ButtonShareTask task={props.task_info}/>
              </Form.Item>
            </VisableItem>
            {/* {props.jupyter_url ? <Form.Item label="高级分析功能">
              <Form.Text><a href={props.jupyter_url} target="_blank"
                rel="noopener noreferrer">进入</a></Form.Text>
            </Form.Item> : ''} */}
          </Form>
        </Col>
      </Row>
    </Card.Body>
  </Card>;
};

TaskInfoCard.propTypes = {
  task_info: PropTypes.object,
  analysis_version: PropTypes.string,
  jupyter_url: PropTypes.string,
};

